import { createStore } from 'vuex'


const defaultLang = () => {

  let vueAppLanguage;
  vueAppLanguage = ((navigator.language || navigator.browserLanguage || window.navigator.language) == 'zh-TW' ? "zh-cn" : (navigator.language || navigator.browserLanguage || window.navigator.language) == 'zh-CN' ? "zh-cn" : 'en');
  return vueAppLanguage;
};
export default createStore({
  state: {
    lang: defaultLang()
  },
  getters: {
  },
  mutations: {
    // 切換語系設定
    setLang(state, value) {
      state.lang = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
