<template>
  <div class="reservedBox"></div>
  <header ref="nav" class="header">
    <router-link to="/">
      <img class="logo" src="../assets/image/logo.png" alt="" />
    </router-link>
    <nav class="header-nav">
      <router-link v-for="item in menuItems" :key="item" :to="item.to">{{
        item.text
      }}</router-link>
    </nav>
    <div class="linkList" :class="{ active: isClose }">
      <router-link
        v-for="item in menuItems"
        :key="item"
        @click="changePage"
        :to="item.to"
        >{{ item.text }}
      </router-link>
    </div>
    <div class="mobile">
      <!-- <div v-show="isOpen" class="mask" :class="{ active: isClose }"></div> -->
      <!-- <div v-show="isOpen" class="menuMobile">
        <div class="linkList" :class="{ active: isClose }">
          <router-link
            v-for="item in menuItems"
            :key="item"
            @click="changePage"
            :to="item.to"
            >{{ item.text }}</router-link
          >
        </div>
      </div> -->
      <div id="burger" :class="{ active: isOpen }" @click.prevent="toggle">
        <slot>
          <button type="button" class="burgerButton" title="Menu">
            <span class="burgerBar burgerBar--1"></span>
            <span class="burgerBar burgerBar--2"></span>
            <span class="burgerBar burgerBar--3"></span>
          </button>
        </slot>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onUnmounted, onBeforeMount, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { useStore } from "vuex";
const store = useStore();
const menuItems = ref([]);

const isOpen = ref(false);
const isClose = ref(false);

const toggle = () => {
  if (isOpen.value === null) {
    isOpen.value = !isOpen.value;
  } else {
    isClose.value = !isClose.value;
    isOpen.value = !isOpen.value;
  }
};

const isCheck = () => {
  if (window.innerWidth >= 1024) {
    isOpen.value = false;
    isClose.value = false;
  }
};

const changePage = () => {
  if (isOpen.value === null) {
    isOpen.value = false;
  } else {
    isClose.value = false;
    isOpen.value = false;
  }
};

const nav = ref(null);
// const navOffsetTop = ref(0);
onBeforeMount(() => {
  document.title = t("pageTitle")
  menuItems.value = [
    { to: "/", text: t("home") },
    { to: "/about", text: t("about") },
    { to: "/contact", text: t("contact") },
  ];
});
onMounted(() => {
  window.addEventListener("resize", isCheck);
  // 增加事件監聽，監聽對象為 window，動作為'scroll'
  window.addEventListener("scroll", fixNav, true);
});

const fixNav = () => {
  if (window.scrollY > 300) {
    nav.value.classList.add("nav-fixed");
  } else {
    nav.value.classList.remove("nav-fixed");
  }
};

onUnmounted(() => {
  window.removeEventListener("resize", isCheck);
});

watch(
  () => store.state.lang,
  () => {
    menuItems.value = [
      { to: "/", text: t("home") },
      { to: "/about", text: t("about") },
      { to: "/contact", text: t("contact") },
    ];
    document.title = t("pageTitle")
    // document.title = '123'
  },
  { deep: true }
);
</script>
<style lang="scss">
.reservedBox {
  width: 100%;
  height: 118px;
}
.header {
  display: flex;
  padding: 8px 150px;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  width: 100%;
  z-index: 2;
  background: #fff;
  position: absolute;
  top: 0;

  &-nav {
    display: flex;
    a {
      margin-left: 44px;
    }
  }

  .mobile {
    display: none;

    .mask {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      animation: closeMask 0.3s linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .mask.active {
      animation: openMask 0.3s linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    // .menuMobile {
    //   position: fixed;
    //   top: 0;
    //   bottom: 0;
    //   right: 0;
    //   left: 0;
    //   width: 100%;
    //   // height: 142px; 1
    //   display: flex;
    //   padding-top: 118px;
    //   justify-content: center;
    //   z-index: 2;

    //   // .linkList {
    //   //   width: 90%;
    //   //   // height: max-content;
    //   //   height: 0;
    //   //   display: flex;
    //   //   flex-flow: column;
    //   //   font-size: 3.5vw;
    //   //   animation: opacityOff 0.3s linear;
    //   //   animation-iteration-count: 1;
    //   //   animation-fill-mode: forwards;
    //   //   background: #fff;
    //   //   position: relative;
    //   //   // bottom: 90px;
    //   //   overflow: hidden;

    //   //   a {
    //   //     font-size: 14px;
    //   //     font-weight: 400;
    //   //     padding: 8px 15px;

    //   //     &:hover {
    //   //       animation: hoverColor 0.3s linear;
    //   //       animation-iteration-count: 1;
    //   //       animation-fill-mode: forwards;
    //   //     }
    //   //   }
    //   // }

    //   // .linkList.active {
    //   //   animation: opacityOn 0.5s linear;
    //   //   animation-iteration-count: 1;
    //   //   animation-fill-mode: forwards;
    //   // }
    // }

    button {
      cursor: pointer;
    }

    /* remove blue outline */
    button:focus {
      outline: 0;
    }

    .burgerButton {
      position: relative;
      height: 30px;
      width: 32px;
      display: block;
      z-index: 60;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      pointer-events: all;
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .burgerBar {
      background-color: #000;
      position: absolute;
      top: 50%;
      right: 6px;
      left: 6px;
      height: 2px;
      width: auto;
      margin-top: -1px;
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
        opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .burgerBar--1 {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
    }

    .burgerBar--2 {
      transform-origin: 100% 50%;
      transform: scaleX(0.8);
    }

    .burgerButton:hover .burgerBar--2 {
      transform: scaleX(1);
    }

    .no-touchevents .burgerBar--2:hover {
      transform: scaleX(1);
    }

    .burgerBar--3 {
      transform: translateY(6px);
    }

    #burger.active .burgerButton {
      transform: rotate(-180deg);
    }

    #burger.active .burgerBar--1 {
      transform: rotate(45deg);
      background-color: #000;
    }

    #burger.active .burgerBar--2 {
      opacity: 0;
    }

    #burger.active .burgerBar--3 {
      transform: rotate(-45deg);
      background-color: #000;
    }
  }

  .linkList {
    width: 90%;
    // height: max-content;
    height: 0;
    display: flex;
    flex-flow: column;
    font-size: 3.5vw;
    animation: opacityOff 0.3s linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    background: #fff;
    position: absolute;
    // bottom: 90px;
    overflow: hidden;
    top: 118px;
    border-bottom: 1px solid #eee;

    a {
      font-size: 14px;
      font-weight: 400;
      padding: 8px 15px;

      &:hover {
        animation: hoverColor 0.3s linear;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
  }

  .linkList.active {
    animation: opacityOn 0.5s linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.nav-fixed {
  position: fixed;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  animation: navFixed 0.5s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes openMask {
  0% {
    height: 0vh;
  }

  100% {
    height: 100vh;
  }
}

@keyframes closeMask {
  0% {
    height: 100vh;
  }

  100% {
    height: 0vh;
  }
}

@keyframes opacityOn {
  0% {
    opacity: 0;
    // bottom: 90px;
    height: 0;
    // transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    // bottom: 0;
    height: 90px;
    // transform: translateY(0);
  }
}

@keyframes hoverColor {
  0% {
    color: #000;
  }

  100% {
    color: #ff5e13;
  }
}

@keyframes opacityOff {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes navFixed {
  0% {
    opacity: 0;
    top: -118px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@media (max-width: 991px) {
  .header {
    padding-left: 20px;
    padding-right: 20px;
    &-nav {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
}
</style>
