<template>
  <footer class="footer">
    <div class="footer-lanBox">
      <i class="ti-world"></i>
      <!-- <select class="footer-lanBox-select" v-model="selectedLan">
        <option selected>繁體中文</option>
        <option>English</option>
      </select> -->
      <el-select
        @change="setLang"
        v-model="langValue"
        placeholder="Select"
        style="width: 120px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="footer-content">
      Copyright © 2024 {{ $t("footerText") }} All Rights Reserved.
    </div>
  </footer>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
const store = useStore();
const { locale } = useI18n();
const langValue = ref("zh-cn");

const options = [
  {
    value: "zh-cn",
    label: "繁體中文",
  },
  {
    value: "en",
    label: "English",
  },
];

// 儲存切換的語系
const setLang = (e) => {
  locale.value = e;
  store.commit("setLang", e);
  localStorage.setItem("locale", e);
};

onBeforeMount(() => {
  if (localStorage.getItem("locale")) {
    locale.value = localStorage.getItem("locale");
    langValue.value = localStorage.getItem("locale");
    store.commit("setLang", localStorage.getItem("locale"));
  } else langValue.value = store.state.lang;
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.footer {
  background-image: url(../assets/image/footer.png);
  text-align: center;
  padding: 25px;
  font-size: 14px;
  color: #9e9e9e;
  font-weight: 400;
  position: relative;
  display: flex;
  flex-flow: wrap;
  // align-items: center;

  &-lanBox {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    // left: 20px;
    transform: translate(0%, -50%);

    i {
      margin-right: 5px;
    }

    .el-select__wrapper {
      background: none;
      box-shadow: none;

      .el-select__placeholder {
        color: #fff;
      }
    }
  }

  &-content {
    width: 100%;
  }
  @media (max-width: 768px) {
    &-lanBox {
      position: relative;
    }
  }
}

// .el-popper.is-light.is-pure{
//       background: #171b1d;
//     }
</style>
