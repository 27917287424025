<template>
  <div class="home">
    <swiper
      :loop="true"
      :height="600"
      class="mySwiper"
      :autoplay="{
          delay: 50000,
          disableOnInteraction: false,
        }"
      :modules="modules"
    >
      <swiper-slide>
        <div class="bannerBox">
          <h3>{{ $t('bannerTextI') }}</h3>
          <p>{{ $t('bannerTextII') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="bannerBox">
          <h3>{{ $t('bannerTextI') }}</h3>
          <p>{{ $t('bannerTextII') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="bannerBox">
          <h3>{{ $t('bannerTextI') }}</h3>
          <p>{{ $t('bannerTextII') }}</p>
        </div>
      </swiper-slide>
    </swiper>

    <div class="aboutArea">
      <div class="aboutArea-leftBox">
        <img
          class="aboutArea-leftBox-img"
          src="../assets/image/aboutArea.jpg"
          alt=""
        />
        <div class="aboutArea-leftBox-content">
          <h1>{{ $t('aboutAreaContentI') }}</h1>
          <span>{{ $t('aboutAreaContentII') }}</span>
        </div>
      </div>
      <div class="aboutArea-rightBox">
        <div class="sectionTitle">
          <span class="subHeading">{{ $t('aboutAreaContentIII') }}</span>
          <h3>{{ $t('aboutAreaContentIV') }}</h3>
          <div class="seperator"></div>
        </div>
        <p>
          {{ $t('aboutAreaContentV') }}
        </p>
        <ul class="aboutList">
          <li>{{ $t('aboutAreaContentVI') }}</li>
          <li>{{ $t('aboutAreaContentVII') }}</li>
          <li>{{ $t('aboutAreaContentVIII') }}</li>
        </ul>
      </div>
    </div>

    <div class="dreamService">
      <div class="dreamService-header">
        <span class="subHeading">{{ $t('dreamServiceTextI') }}</span>
        <h3>{{ $t('dreamServiceTextII') }}</h3>
      </div>
      <div class="dreamService-content">
        <div class="dreamService-content-item">
          <div class="thumb">
            <img src="../assets/image/dreamService1.png" alt="客服服務監控" />
          </div>
          <h3>{{ $t('dreamServiceTextIII') }}</h3>
          <p>
            {{ $t('dreamServiceTextIV') }}
          </p>
        </div>
        <div class="dreamService-content-item">
          <div class="thumb">
            <img src="../assets/image/dreamService2.png" alt="客服服務監控" />
          </div>
          <h3>{{ $t('dreamServiceTextV') }}</h3>
          <p>
            {{ $t('dreamServiceTextVI') }}
          </p>
        </div>
        <div class="dreamService-content-item">
          <div class="thumb">
            <img src="../assets/image/dreamService3.png" alt="客服服務監控" />
          </div>
          <h3>{{ $t('dreamServiceTextVII') }}</h3>
          <p>
            {{ $t('dreamServiceTextVIII') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import { Autoplay } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  .bannerBox {
    position: relative;
    height: 600px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../assets/image/banner.jpg);
    z-index: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0 50px;

    h3 {
      color: #ffffff;
      font-weight: 700;
      font-family: "Microsoft Yahei", "Roboto", sans-serif;
      font-size: 60px;
      text-transform: uppercase;
      letter-spacing: 5px;
      text-align: center;
      line-height: 1;
    }

    p {
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 55px;
      margin-top: 15px;
      text-align: center;
    }

    &::before {
      position: absolute;
      content: "";
      background-color: #131313;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.2;
    }
  }
  .subHeading {
    color: #c9c9c9;
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: "Microsoft Yahei", "Roboto", sans-serif;
    letter-spacing: 3px;
    margin-bottom: 22px;
  }
  h3 {
    font-size: 46px;
    font-weight: 500;
    line-height: 58px;
    margin-bottom: 0;
    // text-align: center;
  }
  .aboutArea {
    padding: 100px 0;
    display: flex;
    max-width: 1140px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    flex-flow: wrap;

    &-leftBox,
    &-rightBox {
      width: 50%;
    }
    &-leftBox {
      // height: 100px;
      padding: 0 15px;
      box-sizing: border-box;
      position: relative;

      &-img {
        width: 100%;
      }

      &-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        flex-flow: column;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 45px;

        h1 {
          color: #131313;
          font-weight: 700;
          font-size: 80px;
          margin-bottom: 15px;
          text-align: center;
        }
        span {
          font-weight: 400;
          font-size: 14px;
          text-transform: uppercase;
          color: #808080;
          text-align: center;
        }
      }
    }
    &-rightBox {
      height: auto;
      // padding: 0px 100px;
      padding-left: 100px;
      box-sizing: border-box;
      .sectionTitle {
        .seperator {
          display: inline-block;
          width: 80%;
          height: 1px;
          background: #808080;
          margin-bottom: 15px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        color: #808080;
      }
      .aboutList {
        margin-bottom: 50px;
        margin-top: 34px;

        li {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: #808080;
          position: relative;
          padding-left: 35px;
          margin-bottom: 6px;
          &::before {
            content: "";
            background: #02c856;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .dreamService {
    padding: 100px 15px;
    max-width: 1140px;
    margin: 0 auto;
    &-header {
      margin-bottom: 95px;
      display: flex;
      flex-flow: column;
      align-items: center;

      h3{
        text-align: center;
      }
    }
    &-content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-flow: wrap;
      &-item {
        // flex: 1;
        width: 33%;
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 0 35px;
        box-sizing: border-box;
        h3 {
          color: #131313;
          font-size: 26px;
          font-weight: 500;
          margin-top: 44px;
          margin-bottom: 20px;
          line-height: 1.2;
          text-align: center;
        }
        p {
          font-size: 14px;
          margin-bottom: 0;
          line-height: 28px;
          font-weight: 400;
          color: #808080;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .aboutArea {
      &-rightBox {
        padding: 15px;
        padding-top: 30px;
      }
    }
  }
  @media (max-width: 991px) {
    .bannerBox {
      h3 {
        font-size: 30px;
        letter-spacing: 3px;
      }
    }
    .aboutArea {
      &-leftBox {
        h1 {
          font-size: 30px;
        }
      }
      &-rightBox {
        .sectionTitle {
          h3 {
            font-size: 25px;
          }
        }
      }
    }
    .dreamService {
      &-header {
        h3 {
          font-size: 25px;
          font-weight: 500;
          line-height: 30px;
          margin-bottom: 0;
        }
      }
      &-content {
        &-item {
          padding: 0 25px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .bannerBox {
      height: 500px;
    }
    .aboutArea {
      padding: 30px 0;

      &-leftBox,
      &-rightBox {
        width: 540px;
        max-width: 540px;
      }
    }
    .dreamService {
      padding-top: 30px;
      padding-bottom: 0;
      &-header {
        margin-bottom: 30px;
      }
      &-content {
        &-item {
          width: 80%;
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>
