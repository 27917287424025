import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/default.scss'
import { createI18n } from 'vue-i18n'
import zh_cn from '../src/lang/translations/zh_cn.json'; // 存放中文語系檔
import en from '../src/lang/translations/en.json'; // 存放英文語系檔
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const i18n = createI18n({
  // something vue-i18n options here ...
  locale: store.state.lang,
  allowComposition: true,
  messages: {
    "zh-cn": zh_cn,
    "en": en,
  },
})
createApp(App).use(i18n).use(store).use(router).use(ElementPlus).mount('#app')
